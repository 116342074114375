import React, { useEffect } from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom'
import RequestResetPage from './pages/request-reset'
import ResetPasswordPage from './pages/reset-password'

const App: React.FC = () => {
  const getAASAFile = () => {
    fetch('apple-app-site-association.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
      .then(function(response) {
        return response.json();
      })
  }

  useEffect(() => {
    getAASAFile()
  }, [])

  return (
    <Router>
      <Route path="/apple-app-site-association" render={() => <App />} />
      <Route exact path="/request-reset" component={RequestResetPage} />
      <Route exact path="/reset-password" component={ResetPasswordPage} />
    </Router>
  )
}

export default App;
