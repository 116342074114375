import { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { resetPassword } from "../graphql";
import { GraphQLError } from "graphql";
import { handleGraphQLError } from "../utils/errors";

const RequestResetPage = () => {
  const [showSuccessModal, setShow] = useState(false);
  const handleSuccessModalClose = () => setShow(false);
  const handleSuccessModalShow = () => setShow(true);

  const [showValidationModal, setValidationShow] = useState(false);
  const handleValidationModalClose = () => setValidationShow(false);
  const handleValidationModalShow = () => setValidationShow(true);

  const [errorMessage, setErrorMessage] = useState("");

  const [showErrorModal, setErrorShow] = useState(false);
  const handleErrorModalClose = () => setErrorShow(false);
  const handleErrorModalShow = (errMessage: string) => {
    setErrorMessage(errMessage);
    setErrorShow(true);
  };

  // State to store value from the input field
  const [passwordValueOne, setPasswordValueOne] = useState("");
  const [passwordValueTwo, setPasswordValueTwo] = useState("");

  // Input Field handler
  const handlePasswordInputOne = (e: any) => {
    setPasswordValueOne(e.target.value);
  };

  const handlePasswordInputTwo = (e: any) => {
    setPasswordValueTwo(e.target.value);
  };

  // Reset Input Field handler
  const resetPasswordFields = () => {
    setPasswordValueOne("");
    setPasswordValueTwo("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (passwordValueOne !== passwordValueTwo || passwordValueOne.length < 6) {
      resetPasswordFields();
      handleValidationModalShow();
      return;
    }

    try {
      const urlParams = new URLSearchParams(window.location.search);
      await resetPassword(passwordValueOne, urlParams.get("token") ?? "");
    } catch (e: any) {
      resetPasswordFields();
      const errMessage = handleGraphQLError(e.graphQLErrors[0] as GraphQLError);
      handleErrorModalShow(errMessage ?? "");
      return;
    }

    resetPasswordFields();
    handleSuccessModalShow();
  };

  return (
    <Container className="p-3">
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Control
            type="password"
            value={passwordValueOne}
            placeholder="Enter your new password"
            onChange={handlePasswordInputOne}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            type="password"
            value={passwordValueTwo}
            placeholder="Confirm your new password"
            onChange={handlePasswordInputTwo}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>

      <Modal
        show={showSuccessModal}
        onHide={handleSuccessModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your account password has been reset. Log in with your new
          credentials.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showValidationModal}
        onHide={handleValidationModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Password Requirements</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Password validation error, please follow these guidelines:</p>
          <ul>
            <li>Your new password must be entered twice</li>
            <li>Your new password must be at least 6 characters in length</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleValidationModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showErrorModal}
        onHide={handleErrorModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Error Encountered</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleErrorModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RequestResetPage;
