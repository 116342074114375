import {
    ApolloClient,
    InMemoryCache,
    gql
} from "@apollo/client"


const client = new ApolloClient({
    uri: process.env.REACT_APP_API_URL,
    cache: new InMemoryCache()
});


export async function requestPasswordReset(emailOrUsername: string) {

    const REQUEST_RESET = gql`
        mutation RequestReset($emailOrUsername: String!) {
            requestPasswordReset(emailOrUsername: $emailOrUsername)
        }
  `;

    return client.mutate({
        mutation: REQUEST_RESET,
        variables: {
            emailOrUsername: emailOrUsername
        }
    })
}

export async function resetPassword(password: string, token: string) {

    const RESET_PASSWORD = gql`
        mutation ResetPassword($password: String!, $token: String!) {
            resetPassword(input: {password: $password, confirmPassword: $password, token: $token})
        }
    `

    return client.mutate({
        mutation: RESET_PASSWORD,
        variables: {
            password: password,
            token: token
        }
    })

}
