import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { requestPasswordReset } from '../graphql'


const RequestResetPage = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // State to store value from the input field
  const [inputValue, setInputValue] = useState("");

  // Input Field handler
  const handleUserInput = (e: any) => {
    setInputValue(e.target.value);
  };

  // Reset Input Field handler
  const resetInputField = () => {
    setInputValue("");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      await requestPasswordReset(inputValue)
    } catch (e) {
      console.log(e)
    }
    resetInputField()
    handleShow()
  }

  return (

    <Container className="p-3">
      <Form onSubmit={handleSubmit}>

        <Form.Group controlId="formBasicEmail">
          <Form.Control type="text" value={inputValue} placeholder="Email or username" onChange={handleUserInput} required />
          <Form.Text className="text-muted">
            Enter your email address or username and you will receive an email with reset instructions
          </Form.Text>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>

      </Form>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Password Reset Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If your account is found you will receive an email with instructions to reset your password.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </Container>
  );
}

export default RequestResetPage
